/*
 * JavaScript tracker for Snowplow: tracker.js
 *
 * Significant portions copyright 2010 Anthon Pang. Remainder copyright
 * 2012-2016 Snowplow Analytics Ltd. All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are
 * met:
 *
 * * Redistributions of source code must retain the above copyright
 *   notice, this list of conditions and the following disclaimer.
 *
 * * Redistributions in binary form must reproduce the above copyright
 *   notice, this list of conditions and the following disclaimer in the
 *   documentation and/or other materials provided with the distribution.
 *
 * * Neither the name of Anthon Pang nor Snowplow Analytics Ltd nor the
 *   names of their contributors may be used to endorse or promote products
 *   derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
 * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
 * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
 * A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
 * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
 * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
 * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
 * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */

import {
    addEventListener,
    attemptGetLocalStorage,
    attemptWriteLocalStorage,
    cookie,
    decorateQuerystring,
    findRootDomain,
    fixupDomain,
    fixupTitle,
    fixupUrl,
    getReferrer,
    isValueInArray,
    warn,
} from './Utilities'

import BrowserFeatureDetector from './BrowserFeatureDetector'
import sha1 from 'sha1'
import OutQueueManager from './OutQueueManager'
import { trackerCore as coreConstructor } from '@snowplow/tracker-core'
import uuid from 'uuid/v4'
import ConfigManager from './ConfigManager'

// Symbols for private methods
const refreshUrl = Symbol()
const linkDecorationHandler = Symbol()
const decorateLinks = Symbol()
const purify = Symbol()
const sendRequest = Symbol()
const getSnowplowCookieName = Symbol()
const getSnowplowCookieValue = Symbol()
const updateDomainHash = Symbol()
const activityHandler = Symbol()
const scrollHandler = Symbol()
const getPageOffsets = Symbol()
const resetMaxScrolls = Symbol()
const cleanOffset = Symbol()
const updateMaxScrolls = Symbol()
const setSessionCookie = Symbol()
const setDomainUserIdCookie = Symbol()
const setCookie = Symbol()
const createNewDomainUserId = Symbol()
const initializeIdsAndCookies = Symbol()
const loadDomainUserIdCookie = Symbol()
const addBrowserData = Symbol()

const asCollectorUrl = Symbol()
const addCommonContexts = Symbol()
const resetPageView = Symbol()
const getPageViewId = Symbol()

const getWebPageContext = Symbol()
const getPerformanceTimingContext = Symbol()

const enableGeolocationContext = Symbol()
const getGaCookiesContext = Symbol()
const finalizeContexts = Symbol()
const logPageView = Symbol()
const logPagePing = Symbol()
const prefixPropertyName = Symbol()
const trackCallback = Symbol()

const Detector = new BrowserFeatureDetector(window, navigator, screen, document)
const documentAlias = document
const windowAlias = window
const navigatorAlias = navigator

const state = Symbol()
const config = Symbol()

/**
 * Snowplow Tracker class
 * @class JavascriptTracker
 */
class JavascriptTracker {
    /**
     * Snowplow Tracker class constructor
     *
     * @param {String} functionName - global function name
     * @param {String} namespace - The namespace of the tracker object
     * @param {String} version - The current version of the JavaScript Tracker
     * @param {Object} mutSnowplowState - An object containing hasLoaded, registeredOnLoadHandlers, and expireDateTime
     *                                      Passed in by reference in case they are altered by snowplow.js
     * @param {TrackerConfiguration} argmap -  Optional dictionary of configuration options.
     * @returns {JavascriptTracker} - an isntance of the SnowplowTracker
     */
    constructor(functionName, namespace, version, mutSnowplowState, argmap) {
        const _this = this

        this.ConfigManager = new ConfigManager(argmap || {})
        this[config] = this.ConfigManager.config

        /************************************************************
         * * Private members
         * ************************************************************/

        // Debug - whether to raise errors to console and log to console
        // or silence all errors from public methods
        //this.debug = false

        // API functions of the tracker
        //this.apiMethods = {};

        // Safe methods (i.e. ones that won't raise errors)
        // These values should be guarded publicMethods
        //this.safeMethods = {};

        // The client-facing methods returned from tracker IIFE
        //this.returnMethods = {};

        this.mutSnowplowState = mutSnowplowState

        this[state] = {
            get locationArray() {
                return fixupUrl(documentAlias.domain, windowAlias.location.href, getReferrer())
            },
            get domainAlias() {
                return fixupDomain(this.locationArray[0])
            },
            locationHrefAlias: '',
            referrerUrl: '',
            pagePingInterval: 20,
            customReferrer: '',
            requestMethod: 'GET',
            collectorUrl: '',
            customUrl: '',
            lastDocumentTitle: '',
            // get lastDocumentTitle() {
            //     return this.documentAlias.title
            // },
            lastConfigTitle: '',
            activityTrackingEnabled: false,
            minimumVisitTime: 0,
            heartBeatTimer: 0,
            //TODO: Should this be set to true by default?
            discardHashTag: false,
            cookiePath: '/',
            get dnt() {
                return navigatorAlias.doNotTrack || navigatorAlias.msDoNotTrack || windowAlias.doNotTrack
            },
            get doNotTrack() {
                return config.respectDoNotTrack && (this.dnt === 'yes' || this.dnt === '1' || this.dnt === true)
            },
            optOutCookie: false,
            countPreRendered: false,
            get documentCharset() {
                return documentAlias.characterSet || documentAlias.charset
            },
            get forceSecureTracker() {
                return _this[config].forceSecureTracker
            },
            get forceUnsecureTracker() {
                return !_this.forceSecureTracker && _this[config].forceUnsecureTracker
            },
            get browserLanguage() {
                return navigatorAlias.userLanguage || navigatorAlias.language
            },
            get browserFeatures() {
                return Detector.detectBrowserFeatures(
                    _this[config].stateStorageStrategy === 'cookie' || _this[config].stateStorageStrategy === 'cookieAndLocalStorage',
                    _this[getSnowplowCookieName]('testcookie')
                )
            },
            get userFingerprint() {
                return config.userFingerprint === false ? '' : Detector.detectSignature(config.userFingerprintSeed)
            },
            trackerId: `${functionName}_${namespace}`,
            activityTrackingInstalled: false,
            lastActivityTime: null,
            lastEventTime: new Date().getTime(),
            minXOffset: 0,
            maxXOffset: 0,
            minYOffset: 0,
            maxYOffset: 0,
            hash: sha1,
            domainHash: null,
            domainUserId: null,
            memorizedSessionId: 1,
            businessUserId: null,
            geolocationContextAdded: false,
            commonContexts: [],
            preservePageViewId: false,
            pageViewSent: false,
        }

        // Tracker core
        this.core = coreConstructor(true, payload => {
            this[addBrowserData](payload)
            this[sendRequest](payload, this[config].pageUnloadTimer)
        })

        // Manager for local storage queue
        this.outQueueManager = new OutQueueManager(
            functionName,
            namespace,
            mutSnowplowState,
            this[config].stateStorageStrategy === 'localStorage' || config.stateStorageStrategy === 'cookieAndLocalStorage',
            this[config].beacon,
            this[config].post,
            this[config].bufferSize,
            this[config].maxPostBytes
        )

        if (this[config].discoverRootDomain) {
            this[config].cookieDomain = findRootDomain()
        }

        if (this[config].contexts.gaCookies) {
            this[state].commonContexts.push(this[getGaCookiesContext]())
        }

        if (this[config].contexts.geolocation) {
            this[enableGeolocationContext]()
        }

        // Enable base 64 encoding for self-describing events and custom contexts
        this.core.setBase64Encoding(this[config].encodeBase64)

        // Set up unchanging name-value pairs
        this.core.setTrackerVersion(version)
        this.core.setTrackerNamespace(namespace)
        this.core.setAppId(this[config].appId)
        this.core.setPlatform(this[config].platform)
        this.core.setTimezone(Detector.detectTimezone())
        this.core.addPayloadPair('lang', this[state].browserLanguage)
        this.core.addPayloadPair('cs', this[state].documentCharset)

        // Browser features. Cookies, color depth and resolution don't get prepended with f_ (because they're not optional features)
        const bf = this[state].browserFeatures
        Object.keys(bf).forEach(feature => {
            if (feature === 'res' || feature === 'cd' || feature === 'cookie') {
                this.core.addPayloadPair(feature, bf[feature])
            }
        })

        /*
         * Initialize tracker
         */
        this[state].locationHrefAlias = this[state].locationArray[1]
        this[state].referrerUrl = this[state].locationArray[2]

        this[updateDomainHash]()
        this[initializeIdsAndCookies]()

        if (this[config].crossDomainLinker) {
            this[decorateLinks](this[config].crossDomainLinker)
        }

        // Create guarded methods from apiMethods,
        // and set returnMethods to apiMethods or safeMethods depending on value of debug
        //safeMethods = productionize(apiMethods)
        //updateReturnMethods()
        //return returnMethods;
    }

    /**
     * Recalculate the domain, URL, and referrer
     **/
    [refreshUrl]() {
        // If this is a single-page app and the page URL has changed, then:
        //   - if the new URL's querystring contains a "refer(r)er" parameter, use it as the referrer
        //   - otherwise use the old URL as the referer

        //TODO: This might be able to be moved to a object literal function
        const _locationHrefAlias = this[state].locationHrefAlias
        if (this[state].locationArray[1] !== _locationHrefAlias) {
            this[state].ReferrerUrl = getReferrer(_locationHrefAlias)
        }

        this[state].locationHrefAlias = this[state].locationArray[1]
    }

    /**
     * Decorate the querystring of a single link
     *
     * @param event e The event targeting the link
     */
    [linkDecorationHandler](e) {
        const _timestamp = new Date().getTime()
        if (e.target.href) {
            e.target.href = decorateQuerystring(e.target.href, '_sp', `${this[state].domainUserId}.${_timestamp}`)
        }
    }

    /**
     * Enable querystring decoration for links pasing a filter
     * Whenever such a link is clicked on or navigated to via the keyboard,
     * add "_sp={{duid}}.{{timestamp}}" to its querystring
     *
     * @param crossDomainLinker Function used to determine which links to decorate
     */
    [decorateLinks](crossDomainLinker) {
        for (let elt of documentAlias.links) {
            if (!elt.spDecorationEnabled && crossDomainLinker(elt)) {
                addEventListener(elt, 'click', e => this[linkDecorationHandler](e), true)
                addEventListener(elt, 'mousedown', e => this[linkDecorationHandler](e), true)
                // Don't add event listeners more than once
                elt.spDecorationEnabled = true
            }
        }
    }

    /*
     * Removes hash tag from the URL
     *
     * URLs are purified before being recorded in the cookie,
     * or before being sent as GET parameters
     */
    [purify](url) {
        return this[state].discardHashTag ? url.replace(/#.*/, '') : url
    }

    /*
     * Send request
     */
    [sendRequest](request, delay) {
        const now = new Date()

        // Set to true if Opt-out cookie is defined
        let toOptoutByCookie
        if (this[state].optOutCookie) {
            toOptoutByCookie = !!cookie(this[state].optOutCookie)
        } else {
            toOptoutByCookie = false
        }

        if (!(this[state].doNotTrack || toOptoutByCookie)) {
            this.outQueueManager.enqueueRequest(request.build(), this[config].collectorUrl)
            this.mutSnowplowState.expireDateTime = now.getTime() + delay
        }
    }

    /*
     * Get cookie name with prefix and domain hash
     */
    [getSnowplowCookieName](baseName) {
        return `${this[config].cookieName}${baseName}.${this[state].domainHash}`
    }

    /*
     * Cookie getter.
     */
    [getSnowplowCookieValue](cookieName) {
        let fullName = this[getSnowplowCookieName](cookieName)
        if (this[config].stateStorageStrategy === 'localStorage') {
            return attemptGetLocalStorage(fullName)
        } else if (this[config].stateStorageStrategy == 'cookie' || this[config].stateStorageStrategy == 'cookieAndLocalStorage') {
            return cookie(fullName)
        }
    }

    /*
     * Update domain hash
     */
    [updateDomainHash]() {
        this[refreshUrl]()
        this[state].domainHash = this[state].hash((this[config].cookieDomain || this[state].domainAlias) + (this[state].cookiePath || '/')).slice(0, 4) // 4 hexits = 16 bits
    }

    /*
     * Process all "activity" events.
     * For performance, this function must have low overhead.
     */
    [activityHandler]() {
        this[state].lastActivityTime = new Date().getTime()
    }

    /*
     * Process all "scroll" events.
     */
    [scrollHandler]() {
        this[updateMaxScrolls]()
        this[activityHandler]()
    }

    /*
     * Returns [pageXOffset, pageYOffset].
     * Adapts code taken from: http://www.javascriptkit.com/javatutors/static2.shtml
     */
    [getPageOffsets]() {
        const iebody = documentAlias.compatMode && documentAlias.compatMode !== 'BackCompat' ? documentAlias.documentElement : documentAlias.body
        return [iebody.scrollLeft || windowAlias.pageXOffset, iebody.scrollTop || windowAlias.pageYOffset]
    }

    /*
     * Quick initialization/reset of max scroll levels
     */
    [resetMaxScrolls]() {
        const offsets = this[getPageOffsets]()

        const x = offsets[0],
            y = offsets[1]
        this[state].minXOffset = x
        this[state].maxXOffset = x

        this[state].minYOffset = y
        this[state].maxYOffset = y
    }

    /*
     * Check the max scroll levels, updating as necessary
     */
    [updateMaxScrolls]() {
        const offsets = this[getPageOffsets]()

        const x = offsets[0],
            y = offsets[1]
        if (x < this[state].minXOffset) {
            this[state].minXOffset = x
        } else if (x > this[state].maxXOffset) {
            this[state].maxXOffset = x
        }

        if (y < this[state].minYOffset) {
            this[state].minYOffset = y
        } else if (y > this[state].maxYOffset) {
            this[state].maxYOffset = y
        }
    }

    /*
     * Prevents offsets from being decimal or NaN
     * See https://github.com/snowplow/snowplow-javascript-tracker/issues/324
     * TODO: the NaN check should be moved into the core
     */
    [cleanOffset](offset) {
        const rounded = Math.round(offset)
        if (!isNaN(rounded)) {
            return rounded
        }
    }

    /*
     * Sets or renews the session cookie
     */
    [setSessionCookie]() {
        const cookieName = this[getSnowplowCookieName]('ses')
        const cookieValue = '*'
        this[setCookie](cookieName, cookieValue, this[config].sessionCookieTimeout)
    }

    /*
     * Sets the Visitor ID cookie: either the first time loadDomainUserIdCookie is called
     * or when there is a new visit or a new page view
     */
    [setDomainUserIdCookie](_domainUserId, createTs, visitCount, nowTs, lastVisitTs, sessionId) {
        const cookieName = this[getSnowplowCookieName]('id')
        const cookieValue = _domainUserId + '.' + createTs + '.' + visitCount + '.' + nowTs + '.' + lastVisitTs + '.' + sessionId
        this[setCookie](cookieName, cookieValue, this[config].cookieLifetime)
    }

    /*
     * Sets a cookie based on the storage strategy:
     * - if 'localStorage': attemps to write to local storage
     * - if 'cookie': writes to cookies
     * - otherwise: no-op
     */
    [setCookie](name, value, timeout) {
        if (this[config].stateStorageStrategy == 'localStorage') {
            attemptWriteLocalStorage(name, value)
        } else if (this[config].stateStorageStrategy == 'cookie' || this[config].stateStorageStrategy == 'cookieAndLocalStorage') {
            cookie(name, value, timeout, this[state].cookiePath, this[config].cookieDomain)
        }
    }

    /**
     * Generate a pseudo-unique ID to fingerprint this user
     */
    [createNewDomainUserId]() {
        return uuid()
    }

    /*
     * Load the domain user ID and the session ID
     * Set the cookies (if cookies are enabled)
     */
    [initializeIdsAndCookies]() {
        const sesCookieSet = this[config].stateStorageStrategy != 'none' && !!this[getSnowplowCookieValue]('ses')
        const idCookieComponents = this[loadDomainUserIdCookie]()

        if (idCookieComponents[1]) {
            this[state].domainUserId = idCookieComponents[1]
        } else {
            this[state].domainUserId = this[createNewDomainUserId]()
            idCookieComponents[1] = this[state].domainUserId
        }

        this[state].memorizedSessionId = idCookieComponents[6]

        if (!sesCookieSet) {
            // Increment the session ID
            idCookieComponents[3]++
            // Create a new sessionId
            this[state].memorizedSessionId = uuid()
            idCookieComponents[6] = this[state].memorizedSessionId
            // Set lastVisitTs to currentVisitTs
            idCookieComponents[5] = idCookieComponents[4]
        }

        if (this[config].stateStorageStrategy != 'none') {
            this[setSessionCookie]()
            // Update currentVisitTs
            idCookieComponents[4] = Math.round(new Date().getTime() / 1000)
            idCookieComponents.shift()
            this[setDomainUserIdCookie].apply(this, idCookieComponents)
        }
    }

    /*
     * Load visitor ID cookie
     */
    [loadDomainUserIdCookie]() {
        if (this[config].stateStorageStrategy == 'none') {
            return []
        }
        const now = new Date(),
            nowTs = Math.round(now.getTime() / 1000),
            id = this[getSnowplowCookieValue]('id')
        let tmpContainer

        if (id) {
            tmpContainer = id.split('.')
            // cookies enabled
            tmpContainer.unshift('0')
        } else {
            tmpContainer = [
                // cookies disabled
                '1',
                // Domain user ID
                this[state].domainUserId,
                // Creation timestamp - seconds since Unix epoch
                nowTs,
                // visitCount - 0 = no previous visit
                0,
                // Current visit timestamp
                nowTs,
                // Last visit timestamp - blank meaning no previous visit
                '',
            ]
        }

        if (!tmpContainer[6]) {
            // session id
            tmpContainer[6] = uuid()
        }

        return tmpContainer
    }

    /*
     * Attaches common web fields to every request
     * (resolution, url, referrer, etc.)
     * Also sets the required cookies.
     */
    [addBrowserData](sb) {
        let nowTs = Math.round(new Date().getTime() / 1000),
            idname = this[getSnowplowCookieName]('id'),
            sesname = this[getSnowplowCookieName]('ses'),
            ses = this[getSnowplowCookieValue]('ses'),
            id = this[loadDomainUserIdCookie](),
            cookiesDisabled = id[0],
            _domainUserId = id[1], // We could use the global (domainUserId) but this is better etiquette
            createTs = id[2],
            visitCount = id[3],
            currentVisitTs = id[4],
            lastVisitTs = id[5],
            sessionIdFromCookie = id[6]

        let toOptoutByCookie
        if (this[state].optOutCookie) {
            toOptoutByCookie = !!cookie(this[state].optOutCookie)
        } else {
            toOptoutByCookie = false
        }

        if ((this[state].doNotTrack || toOptoutByCookie) && this[config].stateStorageStrategy != 'none') {
            if (this[config].stateStorageStrategy == 'localStorage') {
                attemptWriteLocalStorage(idname, '')
                attemptWriteLocalStorage(sesname, '')
            } else if (this[config].stateStorageStrategy == 'cookie' || this[config].stateStorageStrategy == 'cookieAndLocalStorage') {
                cookie(idname, '', -1, this[state].cookiePath, this[config].cookieDomain, this[config].cookieSameSite, this[config].cookieSecure)
                cookie(sesname, '', -1, this[state].cookiePath, this[config].cookieDomain, this[config].cookieSameSite, this[config].cookieSecure)
            }
            return
        }

        // If cookies are enabled, base visit count and session ID on the cookies
        if (cookiesDisabled === '0') {
            this[state].memorizedSessionId = sessionIdFromCookie

            // New session?
            if (!ses && this[config].stateStorageStrategy != 'none') {
                // New session (aka new visit)
                visitCount++
                // Update the last visit timestamp
                lastVisitTs = currentVisitTs
                // Regenerate the session ID
                this[state].memorizedSessionId = uuid()
            }

            this[state].memorizedVisitCount = visitCount

            // Otherwise, a new session starts if configSessionCookieTimeout seconds have passed since the last event
        } else {
            if (new Date().getTime() - this[state].lastEventTime > this[config].sessionCookieTimeout * 1000) {
                this[state].memorizedSessionId = uuid()
                this[state].memorizedVisitCount++
            }
        }

        // Build out the rest of the request
        sb.add('vp', Detector.detectViewport())
        sb.add('ds', Detector.detectDocumentSize())
        sb.add('vid', this[state].memorizedVisitCount)
        sb.add('sid', this[state].memorizedSessionId)
        sb.add('duid', _domainUserId) // Set to our local variable
        sb.add('fp', this[state].userFingerprint)
        sb.add('uid', this[state].businessUserId)

        this[refreshUrl]()

        sb.add('refr', this[purify](this[state].customReferrer || this[state].referrerUrl))

        // Add the page URL last as it may take us over the IE limit (and we don't always need it)
        sb.add('url', this[purify](this[state].customReferrer || this[state].locationHrefAlias))

        // Update cookies
        if (this[config].stateStorageStrategy != 'none') {
            this[setDomainUserIdCookie](_domainUserId, createTs, this[state].memorizedVisitCount, nowTs, lastVisitTs, this[state].memorizedSessionId)
            this[setSessionCookie]()
        }

        this[state].lastEventTime = new Date().getTime()
    }

    /**
     * Adds the protocol in front of our collector URL, and i to the end
     *
     * @param string rawUrl The collector URL without protocol
     *
     * @return string collectorUrl The tracker URL with protocol
     */
    [asCollectorUrl](rawUrl) {
        if (this[state].forceSecureTracker) {
            return `https://${rawUrl}`
        }
        if (this[state].forceUnsecureTracker) {
            return `http://${rawUrl}`
        }
        return ('https:' === documentAlias.location.protocol ? 'https' : 'http') + `://${rawUrl}`
    }

    /**
     * Add common contexts to every event
     * TODO: move this functionality into the core
     *
     * @param array userContexts List of user-defined contexts
     * @return *[] combined with commonContexts
     */
    [addCommonContexts](userContexts) {
        if (userContexts && Object.entries(userContexts).length > 0) {
            userContexts = {
                schema: 'event',
                data: userContexts.constructor === Array ? userContexts[0] : userContexts,
            }
        } else {
            userContexts = undefined
        }
        let combinedContexts = this[state].commonContexts.concat(userContexts || [])

        if (this[config].contexts.webPage) {
            combinedContexts.push(this[getWebPageContext]())
        }

        // Add PerformanceTiming Context
        if (this[config].contexts.performanceTiming) {
            const performanceTimingContext = this[getPerformanceTimingContext]()
            if (performanceTimingContext) {
                combinedContexts.push(performanceTimingContext)
            }
        }

        // Add cookies from old mamka and nginx
        for (let cookieName of ['marker', 'mamka_auid', 'auid']) {
            let cookieValue = cookie(cookieName)
            if (cookieValue !== '') {
                combinedContexts.push({
                    schema: cookieName,
                    data: { id: cookieValue },
                })
            }
        }

        return combinedContexts
    }

    /**
     * Initialize new `pageViewId` if it shouldn't be preserved.
     * Should be called when `trackPageView` is invoked
     */
    [resetPageView]() {
        if (!this[state].preservePageViewId || this.mutSnowplowState.pageViewId == null) {
            this.mutSnowplowState.pageViewId = uuid()
        }
    }

    /**
     * Safe function to get `pageViewId`.
     * Generates it if it wasn't initialized by other tracker
     */
    [getPageViewId]() {
        if (this.mutSnowplowState.pageViewId == null) {
            this.mutSnowplowState.pageViewId = uuid()
        }
        return this.mutSnowplowState.pageViewId
    }

    /**
     * Put together a web page context with a unique UUID for the page view
     *
     * @return object web_page context
     */
    [getWebPageContext]() {
        return {
            schema: 'webPage',
            data: {
                id: this[getPageViewId](),
            },
        }
    }

    /**
     * Creates a context from the window.performance.timing object
     *
     * @return object PerformanceTiming context
     */
    [getPerformanceTimingContext]() {
        const allowedKeys = [
            'navigationStart',
            'redirectStart',
            'redirectEnd',
            'fetchStart',
            'domainLookupStart',
            'domainLookupEnd',
            'connectStart',
            'secureConnectionStart',
            'connectEnd',
            'requestStart',
            'responseStart',
            'responseEnd',
            'unloadEventStart',
            'unloadEventEnd',
            'domLoading',
            'domInteractive',
            'domContentLoadedEventStart',
            'domContentLoadedEventEnd',
            'domComplete',
            'loadEventStart',
            'loadEventEnd',
            'msFirstPaint',
            'chromeFirstPaint',
            'requestEnd',
            'proxyStart',
            'proxyEnd',
        ]
        const performance = windowAlias.performance || windowAlias.mozPerformance || windowAlias.msPerformance || windowAlias.webkitPerformance
        if (performance) {
            // On Safari, the fields we are interested in are on the prototype chain of
            // performance.timing so we cannot copy them using lodash.clone
            let performanceTiming = {}
            for (let field in performance.timing) {
                if (isValueInArray(field, allowedKeys) && performance.timing[field] !== null) {
                    performanceTiming[field] = performance.timing[field]
                }
            }

            // Old Chrome versions add an unwanted requestEnd field
            delete performanceTiming.requestEnd

            // Add the Chrome firstPaintTime to the performance if it exists
            if (windowAlias.chrome && windowAlias.chrome.loadTimes && typeof windowAlias.chrome.loadTimes().firstPaintTime === 'number') {
                performanceTiming.chromeFirstPaint = Math.round(windowAlias.chrome.loadTimes().firstPaintTime * 1000)
            }

            return {
                schema: 'PerformanceTiming',
                data: performanceTiming,
            }
        }
    }


    /**
     * Attempts to create a context using the geolocation API and add it to commonContexts
     */
    [enableGeolocationContext]() {
        if (!this[state].geolocationContextAdded && navigatorAlias.geolocation && navigatorAlias.geolocation.getCurrentPosition) {
            this[state].geolocationContextAdded = true
            navigatorAlias.geolocation.getCurrentPosition(position => {
                const coords = position.coords
                const geolocationContext = {
                    schema: 'geolocation',
                    data: {
                        latitude: coords.latitude,
                        longitude: coords.longitude,
                        latitudeLongitudeAccuracy: coords.accuracy,
                        altitude: coords.altitude,
                        altitudeAccuracy: coords.altitudeAccuracy,
                        bearing: coords.heading,
                        speed: coords.speed,
                        timestamp: Math.round(position.timestamp),
                    },
                }
                this[state].commonContexts.push(geolocationContext)
            })
        }
    }

    /**
     * Creates a context containing the values of the cookies set by GA
     *
     * @return object GA cookies context
     */
    [getGaCookiesContext]() {
        const gaCookieData = {}
        const gaCookies = ['__utma', '__utmb', '__utmc', '__utmv', '__utmz', '_ga']
        gaCookies.forEach(function(cookieType) {
            const value = cookie(cookieType)
            if (value) {
                gaCookieData[cookieType] = value
            }
        })
        return {
            schema: 'gaCookies',
            data: gaCookieData,
        }
    }

    /**
     * Combine an array of unchanging contexts with the result of a context-creating function
     *
     * @param staticContexts Array of custom contexts
     * @param contextCallback Function returning an array of contexts
     */
    [finalizeContexts](staticContexts, contextCallback) {
        return (staticContexts || []).concat(contextCallback ? contextCallback() : [])
    }

    /**
     * Log the page view / visit
     *
     * @param customTitle string The user-defined page title to attach to this page view
     * @param context object Custom context relating to the event
     * @param contextCallback Function returning an array of contexts
     * @param tstamp number
     */
    [logPageView](customTitle, context, contextCallback, tstamp) {
        //TODO: This function is a monster and probably should be refactored.
        this[refreshUrl]()
        if (this[state].pageViewSent) {
            // Do not reset pageViewId if previous events were not page_view
            this[resetPageView]()
        }
        this[state].pageViewSent = true

        // So we know what document.title was at the time of trackPageView
        this[state].lastDocumentTitle = documentAlias.title
        this[state].lastConfigTitle = customTitle

        // Fixup page title
        const pageTitle = fixupTitle(this[state].lastConfigTitle || this[state].lastDocumentTitle)

        // Log page view
        this.core.trackPageView(
            this[purify](this[state].customUrl || this[state].locationHrefAlias),
            pageTitle,
            this[purify](this[state].customReferrer || this[state].referrerUrl),
            this[addCommonContexts](this[finalizeContexts](context, contextCallback)),
            tstamp
        )

        // Send ping (to log that user has stayed on page)
        const now = new Date()

        if (this[state].activityTrackingEnabled && !this[state].activityTrackingInstalled) {
            this[state].activityTrackingInstalled = true

            // Add mousewheel event handler, detect passive event listeners for performance
            const detectPassiveEvents = {
                update: function update() {
                    if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
                        let passive = false
                        const options = Object.defineProperty({}, 'passive', { get: () => (passive = true) })
                        // note: have to set and remove a no-op listener instead of null
                        // (which was used previously), becasue Edge v15 throws an error
                        // when providing a null callback.
                        // https://github.com/rafrex/detect-passive-events/pull/3
                        const noop = () => {}
                        window.addEventListener('testPassiveEventSupport', noop, options)
                        window.removeEventListener('testPassiveEventSupport', noop, options)
                        detectPassiveEvents.hasSupport = passive
                    }
                },
            }
            detectPassiveEvents.update()

            // Detect available wheel event
            const wheelEvent =
                'onwheel' in document.createElement('div')
                    ? 'wheel' // Modern browsers support "wheel"
                    : document.onmousewheel !== undefined
                        ? 'mousewheel' // Webkit and IE support at least "mousewheel"
                        : 'DOMMouseScroll' // let's assume that remaining browsers are older Firefox

            if (Object.prototype.hasOwnProperty.call(detectPassiveEvents, 'hasSupport')) {
                addEventListener(
                    documentAlias,
                    wheelEvent,
                    () => {
                        this[activityHandler]
                    },
                    { passive: true }
                )
            } else {
                addEventListener(documentAlias, wheelEvent, () => this[activityHandler])
            }

            // Capture our initial scroll points
            this[resetMaxScrolls]()

            // Add event handlers; cross-browser compatibility here varies significantly
            // @see http://quirksmode.org/dom/events
            addEventListener(documentAlias, 'click', () => this[activityHandler])
            addEventListener(documentAlias, 'mouseup', () => this[activityHandler])
            addEventListener(documentAlias, 'mousedown', () => this[activityHandler])
            addEventListener(documentAlias, 'mousemove', () => this[activityHandler])
            addEventListener(windowAlias, 'scroll', () => this[scrollHandler]) // Will updateMaxScrolls() for us
            addEventListener(documentAlias, 'keypress', () => this[activityHandler])
            addEventListener(documentAlias, 'keydown', () => this[activityHandler])
            addEventListener(documentAlias, 'keyup', () => this[activityHandler])
            addEventListener(windowAlias, 'resize', () => this[activityHandler])
            addEventListener(windowAlias, 'focus', () => this[activityHandler])
            addEventListener(windowAlias, 'blur', () => this[activityHandler])

            // Periodic check for activity.
            this[state].lastActivityTime = now.getTime()
            clearInterval(this[state].pagePingInterval)
            this[state].pagePingInterval = setInterval(() => {
                const now = new Date()

                // There was activity during the heart beat period;
                // on average, this is going to overstate the visitDuration by configHeartBeatTimer/2
                if (this[state].lastActivityTime + this[config].heartBeatTimer > now.getTime()) {
                    // Send ping if minimum visit time has elapsed
                    if (this[state].minimumVisitTime < now.getTime()) {
                        this[logPagePing](this[finalizeContexts](context, contextCallback)) // Grab the min/max globals
                    }
                }
            }, this[config].heartBeatTimer)
        }
    }

    /**
     * Log that a user is still viewing a given page
     * by sending a page ping.
     * Not part of the public API - only called from
     * logPageView() above.
     *
     * @param context object Custom context relating to the event
     */
    [logPagePing](context) {
        this[refreshUrl]()
        const newDocumentTitle = documentAlias.title
        if (newDocumentTitle !== this[state].lastDocumentTitle) {
            this[state].lastDocumentTitle = newDocumentTitle
            this[state].lastConfigTitle = null
        }
        this.core.trackPagePing(
            this[purify](this[state].customUrl || this[state].locationHrefAlias),
            fixupTitle(this[state].lastConfigTitle || this[state].lastDocumentTitle),
            this[purify](this[state].customReferrer || this[state].referrerUrl),
            this[cleanOffset](this[state].minXOffset),
            this[cleanOffset](this[state].maxXOffset),
            this[cleanOffset](this[state].minYOffset),
            this[cleanOffset](this[state].maxYOffset),
            this[addCommonContexts](context)
        )
        this[resetMaxScrolls]()
    }

    /**
     * Construct a browser prefix
     *
     * E.g: (moz, hidden) -> mozHidden
     */
    [prefixPropertyName](prefix, propertyName) {
        if (prefix !== '') {
            return prefix + propertyName.charAt(0).toUpperCase() + propertyName.slice(1)
        }

        return propertyName
    }

    /**
     * Check for pre-rendered web pages, and log the page view/link
     * according to the configuration and/or visibility
     *
     * @see http://dvcs.w3.org/hg/webperf/raw-file/tip/specs/PageVisibility/Overview.html
     */
    [trackCallback](callback) {
        let isPreRendered,
            i,
            // Chrome 13, IE10, FF10
            prefixes = ['', 'webkit', 'ms', 'moz'],
            prefix

        // If configPrerendered == true - we'll never set `isPreRendered` to true and fire immediately,
        // otherwise we need to check if this is just prerendered
        if (!this[state].countPreRendered) {
            // true by default

            for (i = 0; i < prefixes.length; i++) {
                prefix = prefixes[i]

                // does this browser support the page visibility API? (drop this check along with IE9 and iOS6)
                if (documentAlias[this[prefixPropertyName](prefix, 'hidden')]) {
                    // if pre-rendered, then defer callback until page visibility changes
                    if (documentAlias[this[prefixPropertyName](prefix, 'visibilityState')] === 'prerender') {
                        isPreRendered = true
                    }
                    break
                } else if (documentAlias[this[prefixPropertyName](prefix, 'hidden')] === false) {
                    break
                }
            }
        }

        const eventHandler = () => {
            documentAlias.removeEventListener(prefix + 'visibilitychange', eventHandler, false)
            callback()
        }

        // Implies configCountPreRendered = false
        if (isPreRendered) {
            // note: the event name doesn't follow the same naming convention as vendor properties
            addEventListener(documentAlias, prefix + 'visibilitychange', eventHandler)
            return
        }

        // configCountPreRendered === true || isPreRendered === false
        callback()
    }

    /**
     * Strip hash tag (or anchor) from URL
     *
     * @param bool enableFilter
     */
    discardHashTag(enableFilter) {
        this[state].discardHashTag = enableFilter
    }

    /**
     * Enable querystring decoration for links pasing a filter
     *
     * @param function crossDomainLinker Function used to determine which links to decorate
     */
    crossDomainLinker(crossDomainLinkerCriterion) {
        this[decorateLinks](crossDomainLinkerCriterion)
    }

    /**
     * Set the business-defined user ID for this user.
     *
     * @param string userId The business-defined user ID
     */
    setUserId(userId) {
        this[state].businessUserId = userId
    }

    /**
     *
     * Specify the Snowplow collector URL. No need to include HTTP
     * or HTTPS - we will add this.
     *
     * @param string rawUrl The collector URL minus protocol and /i
     */
    setCollectorUrl(rawUrl) {
        this[config].collectorUrl = this[asCollectorUrl](rawUrl)
    }

    /**
     * Log visit to this page
     *
     * @param string customTitle
     * @param object Custom context relating to the event
     * @param object contextCallback Function returning an array of contexts
     * @param tstamp number or Timestamp object
     */
    trackPageView(customTitle, context, contextCallback, tstamp) {
        this[trackCallback](() => {
            this[logPageView](customTitle, context, contextCallback, tstamp)
        })
    }

    send_page_view(payload, callbackContext) {
        this[trackCallback](() => {
            this[logPageView](undefined, [payload], callbackContext, null)
        })
    }

    // DO NOT REMOVE IT. FOR MAMKA!!!
    get_params(callback) {
        warn('get_params is deprecated!')
    }

    /**
     * Track a structured event happening on this page.
     *
     * Replaces trackEvent, making clear that the type
     * of event being tracked is a structured one.
     *
     * @param string category The name you supply for the group of objects you want to track
     * @param string action A string that is uniquely paired with each category, and commonly used to define the type of user interaction for the web object
     * @param string label (optional) An optional string to provide additional dimensions to the event data
     * @param string property (optional) Describes the object or the action performed on it, e.g. quantity of item added to basket
     * @param int|float|string value (optional) An integer that you can use to provide numerical data about the user event
     * @param object Custom context relating to the event
     * @param tstamp number or Timestamp object
     */
    trackStructEvent(category, action, label, property, value, context, tstamp) {
        this[trackCallback](() => {
            this.core.trackStructEvent(category, action, label, property, value, this[addCommonContexts](context), tstamp)
        })
    }

    /** Mamka compatibility */
    send_event(payload) {
        let category, action, label
        let context = payload['meta']

        if (payload['name'].includes('--')) {
            ;[category, label, action] = payload['name'].split('--')
        } else {
            category = action = payload['name']
        }
        this[trackCallback](() => {
            this.core.trackStructEvent(category, action, label, null, null, this[addCommonContexts](context), null)
        })
    }

    /**
     * Alias for `trackSelfDescribingEvent`, left for compatibility
     */
    trackUnstructEvent(eventJson, context, tstamp) {
        this[trackCallback](() => {
            this.core.trackSelfDescribingEvent(eventJson, this[addCommonContexts](context), tstamp)
        })
    }

    /**
     * Stop regenerating `pageViewId` (available from `web_page` context)
     */
    preservePageViewId() {
        this[state].preservePageViewId = true
    }
}

export default JavascriptTracker
