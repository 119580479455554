/*
 * JavaScript tracker for Snowplow: Snowplow.js
 *
 * Significant portions copyright 2010 Anthon Pang. Remainder copyright
 * 2012-2014 Snowplow Analytics Ltd. All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are permitted provided that the following conditions are
 * met:
 *
 * * Redistributions of source code must retain the above copyright
 *   notice, this list of conditions and the following disclaimer.
 *
 * * Redistributions in binary form must reproduce the above copyright
 *   notice, this list of conditions and the following disclaimer in the
 *   documentation and/or other materials provided with the distribution.
 *
 * * Neither the name of Anthon Pang nor Snowplow Analytics Ltd nor the
 *   names of their contributors may be used to endorse or promote products
 *   derived from this software without specific prior written permission.
 *
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS
 * "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT
 * LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
 * A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT
 * OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
 * SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 * LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE,
 * DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY
 * THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
 * (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE
 * OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */

/**
 * Cleans up the page title
 *
 * @param {String} title - page title to clean up
 * @returns {String} - cleaned up page title
 */
export const fixupTitle = title => {
    if (!isString(title)) {
        title = title.text || ''

        let tmp = document.getElementsByTagName('title')
        if (tmp && tmp[0] !== undefined) {
            title = tmp[0].text
        }
    }
    return title
}

/**
 * Extract hostname from URL
 *
 * @param {String} url - the url to extract the hostname from
 * @returns {String} - the hostname
 */
export const getHostName = url => {
    // scheme : // [username [: password] @] hostname [: port] [/ [path] [? query] [# fragment]]
    let e = new RegExp('^(?:(?:https?|ftp):)/*(?:[^@]+@)?([^:/#]+)'),
        matches = e.exec(url)

    return matches ? matches[1] : url
}

/**
 * Fix-up domain
 *
 * @param {String} domain - domain to fix up
 * @returns {String} - fixed up domain
 */
export const fixupDomain = domain => {
    let dl = domain.length

    // remove trailing '.'
    if (domain.charAt(--dl) === '.') {
        domain = domain.slice(0, dl)
    }
    // remove leading '*'
    if (domain.slice(0, 2) === '*.') {
        domain = domain.slice(1)
    }
    return domain
}

/**
 * Get page referrer. In the case of a single-page app,
 * if the URL changes without the page reloading, pass
 * in the old URL. It will be returned unless overriden
 * by a "refer(r)er" parameter in the querystring.
 *
 * @param {String} oldLocation  - optional.
 * @returns {String} - the referrer
 */
export const getReferrer = oldLocation => {
    let referrer = ''

    let fromQs = fromQuerystring('referrer', window.location.href) || fromQuerystring('referer', window.location.href)

    // Short-circuit
    if (fromQs) {
        return fromQs
    }

    // In the case of a single-page app, return the old URL
    if (oldLocation) {
        return oldLocation
    }

    try {
        referrer = window.top.document.referrer
    } catch (e) {
        if (window.parent) {
            try {
                referrer = window.parent.document.referrer
            } catch (e2) {
                referrer = ''
            }
        }
    }
    if (referrer === '') {
        referrer = document.referrer
    }
    return referrer
}

/**
 * Cross-browser helper function to add event handler
 *
 * @param {HTMLElement} element - the element to add the event to
 * @param {String} eventType - the type of event to listen to
 * @param {Function} eventHandler - the function to attach
 * @param {Boolean} useCapture - set to true to enable "capture mode"
 * @returns {Boolean} - returns the result of adding the listner, should always be true.
 */
export const addEventListener = (element, eventType, eventHandler, useCapture) => {
    if (element.addEventListener) {
        element.addEventListener(eventType, eventHandler, useCapture)
        return true
    }

    if (element.attachEvent) {
        return element.attachEvent('on' + eventType, eventHandler)
    }

    element['on' + eventType] = eventHandler
    return true
}

export const cookie = function(name, value, ttl, path, domain, samesite = 'None', secure = true) {
    if (arguments.length > 1) {
        return (document.cookie =
            name +
            '=' +
            encodeURIComponent(value) +
            (ttl ? '; expires=' + new Date(+new Date() + ttl * 1000).toUTCString() : '') +
            (path ? '; path=' + path : '') +
            (domain ? '; domain=' + domain : '') +
            ('; SameSite=' + (samesite ? samesite : 'None')) +
            (secure ? '; Secure' : ''))
    }

    return decodeURIComponent((('; ' + document.cookie).split('; ' + name + '=')[1] || '').split(';')[0])
}

/**
 * Return value from name-value pair in querystring
 *
 * @param {String} field - query string field to get value from
 * @param {String} url - the url to get the query string from
 * @returns {String} - the value of the field in the query string
 */
export const fromQuerystring = (field, url) => {
    let match = new RegExp('^[^#]*[?&]' + field + '=([^&#]*)').exec(url)
    if (!match) {
        return null
    }
    return decodeURIComponent(match[1].replace(/\+/g, ' '))
}

/**
 * Only log deprecation warnings if they won't cause an error
 *
 * @param {String} message - the warning message
 */
export const warn = message => {
    if (typeof window.console !== 'undefined') {
        window.console.warn('Snowplow: ' + message)
    }
}

/**
 * Add a name-value pair to the querystring of a URL
 *
 * @param {String} url -  URL to decorate
 * @param {String} name - Name of the querystring pair
 * @param {String} value  - Value of the querystring pair
 * @returns {String} - resultant url
 */
export const decorateQuerystring = (url, name, value) => {
    let initialQsParams = name + '=' + value
    let hashSplit = url.split('#')
    let qsSplit = hashSplit[0].split('?')
    let beforeQuerystring = qsSplit.shift()
    // Necessary because a querystring may contain multiple question marks
    let querystring = qsSplit.join('?')
    if (!querystring) {
        querystring = initialQsParams
    } else {
        // Whether this is the first time the link has been decorated
        let initialDecoration = true
        let qsFields = querystring.split('&')
        for (let i = 0; i < qsFields.length; i++) {
            if (qsFields[i].substr(0, name.length + 1) === name + '=') {
                initialDecoration = false
                qsFields[i] = initialQsParams
                querystring = qsFields.join('&')
                break
            }
        }
        if (initialDecoration) {
            querystring = initialQsParams + '&' + querystring
        }
    }
    hashSplit[0] = beforeQuerystring + '?' + querystring
    return hashSplit.join('#')
}

/**
 * Attempt to get a value from localStorage
 *
 * @param {String} key - the key to read from localStorage
 * @returns {String|undefined} The value obtained from localStorage, or undefined if localStorage is inaccessible
 */
export const attemptGetLocalStorage = key => {
    try {
        return localStorage.getItem(key)
    } catch (e) {
        //The try is to prevent an error, but it is OK to swallow it here as that is expected behaviour
    }
}

/**
 * Attempt to write a value to localStorage
 *
 * @param {String} key - the key to write to in localStorage
 * @param {String} value - the value to write to localStorage
 * @returns {Boolean} true if the operation was successful
 */
export const attemptWriteLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, value)
        return true
    } catch (e) {
        return false
    }
}

/**
 * Finds the root domain. Attempts to use cookies, or defaults to the hostname
 *
 * @returns {String}  - the root domain of the page
 */
export const findRootDomain = () => {
    let cookiePrefix = '_sp_root_domain_test_'
    let cookieName = cookiePrefix + new Date().getTime()
    let cookieValue = '_test_value_' + new Date().getTime()

    let split = window.location.hostname.split('.')
    let position = split.length - 1
    while (position >= 0) {
        let currentDomain = split.slice(position, split.length).join('.')
        cookie(cookieName, cookieValue, 0, '/', currentDomain)
        if (cookie(cookieName) === cookieValue) {
            // Clean up created cookie(s)
            deleteCookie(cookieName, currentDomain)
            let cookieNames = getCookiesWithPrefix(cookiePrefix)
            for (let i = 0; i < cookieNames.length; i++) {
                deleteCookie(cookieNames[i], currentDomain)
            }

            return currentDomain
        }
        position -= 1
    }

    // Cookies cannot be read
    return window.location.hostname
}

/**
 * Checks whether a value is present within an array
 *
 * @param {any} val  - The value to check for
 * @param {Array} array - The array to check within
 * @returns {Boolean}  - Whether it exists
 */
export const isValueInArray = (val, array) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === val) {
            return true
        }
    }
    return false
}

/**
 * Deletes an arbitrary cookie by setting the expiration date to the past
 *
 * @param {String} cookieName -  The name of the cookie to delete
 * @param {String} domainName  - The domain the cookie is in
 */
export const deleteCookie = (cookieName, domainName) => {
    cookie(cookieName, '', -1, '/', domainName)
}

/**
 * Fetches the name of all cookies beginning with a certain prefix
 *
 * @param {String} cookiePrefix - The prefix to check for
 * @returns {Array} an array of the cookies that begin with the prefix
 */
export const getCookiesWithPrefix = function(cookiePrefix) {
    let cookies = document.cookie.split('; ')
    let cookieNames = []
    for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].substring(0, cookiePrefix.length) === cookiePrefix) {
            cookieNames.push(cookies[i])
        }
    }
    return cookieNames
}

/**
 * Extract parameter from URL
 *
 * @param {String} url - url to extract the parameter from
 * @param {String} name - name of the parameter to extract
 * @returns {String} - string value of the extracted parameter
 */
export const getParameter = (url, name) => {
    // scheme : // [username [: password] @] hostname [: port] [/ [path] [? query] [# fragment]]
    let e = new RegExp('^(?:https?|ftp)(?::/*(?:[^?]+))([?][^#]+)'),
        matches = e.exec(url)
    return fromQuerystring(name, matches[1])
}

/**
 * Fix-up URL when page rendered from search engine cache or translated page.
 *
 * @param {String} hostName - the host name of the server
 * @param {String} href - the href of the page
 * @param {String} referrer - the referrer of the page
 */
export const fixupUrl = (hostName, href, referrer) => {
    //TODO: it would be nice to generalise this and/or move into the ETL phase.

    switch (hostName) {
    case 'translate.googleusercontent.com':
        if (referrer === '') {
            referrer = href
        }
        href = getParameter(href, 'u')
        hostName = getHostName(href)
        break

    case 'cc.bingj.com':
    case 'webcache.googleusercontent.com':
    }

    return [hostName, href, referrer]
}

export const isFunction = function(toTest) {
    return typeof toTest == 'function'
}

export const isString = function(toTest) {
    return typeof toTest == 'string'
}

export const mapValues = (obj, iteree) => {
    return Object.entries(obj).reduce((a, [key, value]) => {
        a[key] = iteree(value)
        return a
    }, {})
}

export const murmurhash = (key, seed) => {
    let remainder, bytes, h1, h1b, c1, c2, k1, i

    remainder = key.length & 3 // key.length % 4
    bytes = key.length - remainder
    h1 = seed
    c1 = 0xcc9e2d51
    c2 = 0x1b873593
    i = 0

    while (i < bytes) {
        k1 = (key.charCodeAt(i) & 0xff) | ((key.charCodeAt(++i) & 0xff) << 8) | ((key.charCodeAt(++i) & 0xff) << 16) | ((key.charCodeAt(++i) & 0xff) << 24)
        ++i

        k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff
        k1 = (k1 << 15) | (k1 >>> 17)
        k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff

        h1 ^= k1
        h1 = (h1 << 13) | (h1 >>> 19)
        h1b = ((h1 & 0xffff) * 5 + ((((h1 >>> 16) * 5) & 0xffff) << 16)) & 0xffffffff
        h1 = (h1b & 0xffff) + 0x6b64 + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16)
    }

    k1 = 0

    switch (remainder) {
    case 3:
        k1 ^= (key.charCodeAt(i + 2) & 0xff) << 1
    // eslint-disable-next-line no-fallthrough
    case 2:
        k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8
    // eslint-disable-next-line no-fallthrough
    case 1:
        k1 ^= key.charCodeAt(i) & 0xff

        k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff
        k1 = (k1 << 15) | (k1 >>> 17)
        k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff
        h1 ^= k1
    }

    h1 ^= key.length

    h1 ^= h1 >>> 16
    h1 = ((h1 & 0xffff) * 0x85ebca6b + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff
    h1 ^= h1 >>> 13
    h1 = ((h1 & 0xffff) * 0xc2b2ae35 + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16)) & 0xffffffff
    h1 ^= h1 >>> 16

    return h1 >>> 0
}


export const getCollectorUrl = (debug) => {
    const host = window.location.host
    if (debug || host.match("beta.aviasales")) {
        return 'beta.avsplow.com'
    }

    if (host.match('aviasales') || host === 'vsepoka.ru') {
        return 'sp.aviasales.ru'
    } else {
        return 'avsplow.com'
    }
}
